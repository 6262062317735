/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'claim_checkmark': {
    width: 8,
    height: 7,
    viewBox: '0 0 8 7',
    data: '<path pid="0" d="M1 2.698l2.58 2.547L7.407 1" _stroke="#095A56" stroke-width="1.5" _fill="none" fill-rule="evenodd"/>'
  }
})
